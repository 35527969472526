<template>
  <div class="info tw-flex tw-justify-between">
    <div class="info_left">
      <order-title title="订单信息" />
      <div class="info_item">
        <p class="info_item_label">订单编号：</p>
        <p class="info_item_value">{{order.orderNo}}</p>
      </div>
      <div class="info_item">
        <p class="info_item_label">下单时间：</p>
        <p class="info_item_value">{{order.createdAt}}</p>
      </div>
    </div>
    <div class="info_price tw-flex tw-items-end">
      <p class="info_price_num text_size_24 tw-font-bold">
        <span class="text_size_16 tw-font-bold" v-if="order.totalAmount > 0">￥</span>
        {{order.totalAmount > 0 ? order.totalAmount + '元' : '面议'}}
      </p>
      <p class="info_price_tips text_size_14">
        {{
          {service_chain: '服务链总价格', service: '订单总价格'}[order.orderType]
        }}
      </p>
      <p class="info_price_count text_size_14 tw-flex-1 tw-text-right" v-if="order.orderType === 'service_chain'">
        包含{{order.tabs.length}}个服务
      </p>
    </div>
  </div>
</template>

<script>
import orderTitle from './order-title'
export default {
  components: {
    orderTitle
  },
  props: {
    order: Object
  }

}
</script>

<style lang="scss" scoped>
.info {
  width: 100%;
  padding: 0 100px;
  &_item {
    min-height: 30px;
    padding: 5px 0;
    display: flex;
    &+ & {
      margin-top: 10px;
    }
    &_label {
      width: 100px;
      line-height: 20px;
      padding-left: 10px;
      color: #909399;
    }
    &_value {
      line-height: 20px;
      color: #303133;
    }
  }
  &_price {
    width: 500px;
    height: 66px;
    margin-top: 60px;
    padding: 0 22px 15px 30px;
    background-image: url('../../../../assets/images/price_bg.png');
    &_num {
      color: #FF4343;
      line-height: 33px;
    }
    &_tips {
      margin-left: 10px;
      color: #FF4343;
      line-height: 20px;
    }
    &_count {
      color: #909399;
      line-height: 20px;
    }
  }
}
</style>
