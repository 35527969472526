// 订单详情
<template>
  <div class="detail">
    <detail-header title="订单详情"></detail-header>
    <div class="detail_main page_width">
      <order-info :order="order"></order-info>
      <div class="detail_main_service">
        <order-service
          v-if="order.id && activeService"
          :order="order"
          :active-service.sync="activeService">
        </order-service>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'

import orderInfo from './order-info'
import orderService from './order-service'

export default {
  components: {
    orderInfo,
    orderService
  },
  data () {
    return {
      activeService: '0',
      order: {}
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    }
  },
  mounted () {
    this.getOrderDetail()
  },
  methods: {
    getOrderDetail (id = this.id) {
      api.getOrderDetail(id).then(res => {
        if (res.data.code === 0) {
          this.order = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .detail_main {
    padding: 20px 0 38px 0;
    &_service {
      margin-top: 40px;
    }
  }
}
</style>
