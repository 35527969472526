<template>
  <div class="title tw-flex tw-items-center">
    <p class="text_size_16 tw-font-bold">{{title}}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  height: 60px;
  & > p {
    position: relative;
    padding-left: 13px;
    color: #000000;
    line-height: 22px;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 3px;
      height: 20px;
      background: #3473E6;
    }
  }
}
</style>
